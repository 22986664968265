<template>
  <base-view id="cobra-training">
    <view-header title="Cobra Point Training" image="bg-login.jpg">
      COBRA Point is the website used by Rocky Mountain Reserve for COBRA and
      Direct Billing. This database allows extensive access to account editing
      to provide a successful management of this aspect of your employee’s
      benefits.
    </view-header>
    <view-subnav-section routed :links="links" />
  </base-view>
</template>

<script>
import BaseView from "@/components/layout/BaseView.vue";
import ViewHeader from "@/components/layout/ViewHeader.vue";
import ViewSubnavSection from "@/components/layout/ViewSubnavSection.vue";

export default {
  data() {
    return {
      links: ["Cobra Training 1", "Cobra Training 2", "Cobra Templates"]
    };
  },
  components: {
    BaseView,
    ViewHeader,
    ViewSubnavSection
  }
};
</script>

<style></style>
